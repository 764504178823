import {
  Attribute,
  LocalizedString,
  Price,
  ProductProjection,
  ProductReference,
  ProductType,
  ProductVariant,
} from '@commercetools/platform-sdk';
import { pipe } from 'fp-ts/function';

import * as array from 'fp-ts/Array';
import * as nonEmptyArray from 'fp-ts/NonEmptyArray';
import * as option from 'fp-ts/Option';
import * as ord from 'fp-ts/Ord';
import * as record from 'fp-ts/Record';
import * as N from 'fp-ts/number';

import { countries, countryList, getCountryFromPath } from 'lib/locale';
import { ApiLocale } from 'lib/locale/types';
import { sendReport, sendWarningReport } from 'lib/sendReport';
import translate from 'lib/translate';
import {
  AttributeName,
  getBooleanAttribute,
  getCustomAttribute,
  getEnumAttribute,
  getLocalisedEnumAttribute,
  getLocalisedEnumSetAttribute,
  getLocalisedTextAttribute,
  getPrimaryCategoryAttribute,
  getTextAttribute,
} from 'models/attributes/serializers';
import {
  ColorWayModel,
  NonEmptyVariants,
  ProductDetailsModel,
  SizeModel,
} from 'models/productDetails/types';
import { toProductType } from 'models/productType/serializers';
import { toVariantMap } from 'models/variants/serializers';
import { PriceModel } from 'models/variants/types';
import {
  deriveImages,
  deriveVideosFromAssets,
  isVariantOnPreorder,
  toPrice,
} from 'models/variants/utilities';

export const LOW_STOCK_LEVEL = 3;

export const isColorWayAvailable = (sizes: SizeModel[]): boolean =>
  array.some((size: SizeModel) => size.available)(sizes);

export const isColorWayInStock = (sizes: SizeModel[]): boolean =>
  array.some((size: SizeModel) => size.inStock)(sizes);

export const isColorWayPreorder = (sizes: SizeModel[]): boolean =>
  array.some((size: SizeModel) => size.preorder)(sizes);

export const isSizeAvailable = (
  variant: ProductVariant,
  locale: ApiLocale
): boolean => {
  const available = getBooleanAttribute(
    variant.attributes,
    AttributeName.Available,
    true
  );

  const includedInStores = getLocalisedEnumSetAttribute(
    variant.attributes,
    AttributeName.IncludedStores,
    locale.language
  ).map(store => store.key);

  return Boolean(available && includedInStores.includes(locale.store));
};

export const isSizeInStock = (variant: ProductVariant): boolean => {
  const channels = variant.availability?.channels ?? {};
  return Object.values(channels).some(channel => channel.isOnStock === true);
};

export const isLowStock = (variant: ProductVariant): boolean => {
  const channels = variant.availability?.channels ?? {};
  const quantity = Object.values(channels).reduce(
    (total, channel) => total + (channel.availableQuantity ?? 0),
    0
  );
  return quantity <= LOW_STOCK_LEVEL;
};

const sizeOrder: Record<string, number> = {
  XXS: 1,
  XS: 2,
  S: 3,
  M: 4,
  L: 5,
  XL: 6,
  XXL: 7,
  Y6: 9,
  Y7: 10,
  Y8: 11,
  Y9: 12,
  Y10: 13,
  Y11: 14,
  Y12: 15,
  Y13: 16,
  Y14: 17,
  '36': 18,
  '37': 19,
  '38': 20,
  '39': 21,
  '40': 22,
  '41': 23,
  KIDS: 24,
  '30ml': 25,
  '60ml': 26,
  ONESIZE: 8,
};

export const toSize = (variant: ProductVariant, locale: ApiLocale) => {
  const size = getLocalisedEnumAttribute(
    variant.attributes,
    AttributeName.Size,
    locale.language
  );

  const length = getLocalisedEnumAttribute(
    variant.attributes,
    AttributeName.Length,
    locale.language
  );

  const preorder = isVariantOnPreorder(variant, locale);

  const finalSaleStores = getLocalisedEnumSetAttribute(
    variant.attributes,
    AttributeName.FinalSaleStores,
    locale.language
  );
  const preorderExpectedDispatch = getTextAttribute(
    variant.attributes,
    AttributeName.PreorderExpectedDispatch
  );

  const key = length.key ? `${size.key}-${length.key}` : size.key;
  const label = length.label ? `${size.label} - ${length.label}` : size.label;

  return {
    id: variant.id,
    key,
    label,
    sku: variant.sku ?? '',
    size: size.label,
    length: length.label,
    available: isSizeAvailable(variant, locale),
    inStock: isSizeInStock(variant),
    lowStock: isLowStock(variant),
    preorder,
    finalSale: !!finalSaleStores.find(
      store =>
        store.key === countries[getCountryFromPath(locale.locale, 'gb')].store
    ),
    preorderExpectedDispatch,
    price: toPrice(variant.price as Price),
    sortOrder: sizeOrder[size.key] ?? 9999,
  };
};

const bySortOrder = pipe(
  N.Ord,
  ord.contramap((s: SizeModel) => s.sortOrder)
);

export const toSizes = (
  variants: nonEmptyArray.NonEmptyArray<ProductVariant>,
  locale: ApiLocale
): SizeModel[] =>
  pipe(
    variants,
    nonEmptyArray.map(
      (variant: ProductVariant): SizeModel => toSize(variant, locale)
    ),
    nonEmptyArray.sortBy([bySortOrder])
  );

const byPrice: ord.Ord<SizeModel> = ord.contramap(
  (size: SizeModel) => size.price.discountedPrice
)(N.Ord);

export const calculateMinimumPrice = (sizes: SizeModel[]): PriceModel =>
  pipe(
    sizes,
    nonEmptyArray.fromArray,
    option.fold(
      () => {
        throw Error('There must be prices');
      },
      (someSizes: nonEmptyArray.NonEmptyArray<SizeModel>) => someSizes
    ),
    nonEmptyArray.min(byPrice)
  ).price;

export const calculateMaximumPrice = (sizes: SizeModel[]): PriceModel =>
  pipe(
    sizes,
    nonEmptyArray.fromArray,
    option.fold(
      () => {
        throw Error('There must be prices');
      },
      (someSizes: nonEmptyArray.NonEmptyArray<SizeModel>) => someSizes
    ),
    nonEmptyArray.max(byPrice)
  ).price;

export const toColorWay = (
  key: string,
  variants: nonEmptyArray.NonEmptyArray<ProductVariant>,
  locale: ApiLocale,
  slug: string
): ColorWayModel => {
  const sizes = toSizes(variants, locale);
  const firstVariant = nonEmptyArray.head(variants);
  const swatch = getLocalisedEnumAttribute(
    firstVariant.attributes,
    AttributeName.Swatch,
    locale.language
  );
  const finalSaleStores = getLocalisedEnumSetAttribute(
    firstVariant.attributes,
    AttributeName.FinalSaleStores,
    locale.language
  );

  const sku = variants[0].sku || '000';
  return {
    productCode: sku.substring(0, sku.length - 3),
    sizes,
    url: `/${locale.locale}/p/${slug}/${key}`,
    key: swatch.key,
    label: swatch.label,
    materials: getLocalisedTextAttribute(
      firstVariant.attributes,
      AttributeName.MaterialsText,
      locale.language
    ),
    variantProductDescription: getLocalisedTextAttribute(
      firstVariant.attributes,
      AttributeName.VariantProductDescription,
      locale.language
    ),
    careInstructions: getLocalisedTextAttribute(
      firstVariant.attributes,
      AttributeName.CareInstructionsText,
      locale.language
    ),
    images: deriveImages(firstVariant.images),
    videos: deriveVideosFromAssets(firstVariant.assets),
    gender: getLocalisedEnumAttribute(
      firstVariant.attributes,
      AttributeName.Gender,
      locale.language
    ).label,
    tag: getLocalisedEnumAttribute(
      firstVariant.attributes,
      AttributeName.Tag,
      locale.language
    ).label,
    finalSale: !!finalSaleStores.find(
      store =>
        store.key === countries[getCountryFromPath(locale.locale, 'gb')].store
    ),
    available: isColorWayAvailable(sizes),
    inStock: isColorWayInStock(sizes),
    preorder: isColorWayPreorder(sizes),
    minPrice: calculateMinimumPrice(sizes),
    maxPrice: calculateMaximumPrice(sizes),
  };
};

export const toAlternates = (
  variants: ProductVariant[],
  slug: LocalizedString,
  locale: ApiLocale
): string[] => {
  const languagesAndSlugs = Object.entries(slug);

  const availableStores = getLocalisedEnumSetAttribute(
    variants.flatMap(v => v.attributes as Attribute[]),
    AttributeName.IncludedStores,
    locale.language
  );

  const localizedSlugs = availableStores
    .flatMap(store =>
      countryList
        .filter(country => country.store === store.key)
        .map(country =>
          languagesAndSlugs.map(
            ([l, s]) => `${l}-${country.code.toLowerCase()}/p/${s}`
          )
        )
        .flat(3)
    )
    .sort();

  return localizedSlugs;
};

export const toColorWays = (
  variants: ProductVariant[],
  locale: ApiLocale,
  slug: string
): ColorWayModel[] =>
  Object.values(
    pipe(
      variants,
      nonEmptyArray.groupBy(
        (variant: ProductVariant) =>
          getLocalisedEnumAttribute(
            variant.attributes,
            AttributeName.Swatch,
            locale.language
          ).key
      ),
      record.mapWithIndex(
        (key: string, variant: nonEmptyArray.NonEmptyArray<ProductVariant>) =>
          toColorWay(key, variant, locale, slug)
      )
    )
  );

export const selectDefaultColorWay = (
  colorWays: ColorWayModel[],
  masterVariantSku: string
): ColorWayModel => {
  return pipe(
    colorWays,
    array.findFirst((colorWay: ColorWayModel) => {
      return (
        array.some((size: SizeModel) => size.sku === masterVariantSku)(
          colorWay.sizes
        ) &&
        colorWay.available &&
        (colorWay.preorder || colorWay.inStock)
      );
    }),
    option.fold(
      () => {
        return pipe(
          colorWays,
          array.findFirst(
            (colorWay: ColorWayModel) =>
              colorWay.available && (colorWay.preorder || colorWay.inStock)
          ),
          option.fold(
            () => {
              return pipe(
                colorWays,
                array.findFirst(
                  (colorWay: ColorWayModel) => colorWay.available
                ),
                option.fold(
                  () => {
                    throw new Error(
                      `Product ${masterVariantSku} must have at least one available color way, it's most likely entirely out of stock`
                    );
                  },
                  colorWay => colorWay
                )
              );
            },
            colorWay => colorWay
          )
        );
      },
      (colorWay: ColorWayModel) => colorWay
    )
  );
};

export const derivePrice = (variant: ProductVariant, locale: ApiLocale) => {
  const prices = variant.prices ?? [];

  const price = prices.find(
    p =>
      p.country === locale.country && p.value.currencyCode === locale.currency
  );
  if (price) {
    return toPrice(price);
  }

  const fallbackPrice = prices.find(
    p => p.value.currencyCode === locale.currency
  );
  if (fallbackPrice) {
    return toPrice(fallbackPrice);
  }

  throw Error('Product needs to have a prices');
};

export const removeNonFullBodyImages = (
  images: ProductVariant['images'] = []
): ProductVariant['images'] => {
  const firstImage = images.find(image =>
    image.url.toLowerCase().endsWith('_1.jpg')
  );

  const secondImage = images.find(image =>
    image.url.toLowerCase().endsWith('_2.jpg')
  );

  if (firstImage && secondImage) {
    return [firstImage, secondImage];
  }

  return images;
};

function reduceRelatedProducts(relatedProducts: Attribute[][]): string[] {
  return relatedProducts.reduce(
    (acc: string[], relatedProduct: Array<Attribute>) => {
      const productReference = relatedProduct.find(
        ({ name }) => name === 'product'
      )?.value as ProductReference;
      const product = productReference.obj?.masterData
        .current as ProductProjection;
      const defaultVariantSku = relatedProduct.find(
        ({ name }) => name === 'variant'
      )?.value as string | undefined;

      if(!product) {
        return acc;
      }
      
      const sku = product.key || defaultVariantSku || product.masterVariant.sku;
      sku && acc.push(sku);
      return acc;
    },
    []
  );
}

export const getRelatedProductSkus = (
  masterVariant: ProductVariant,
  variants: ProductVariant[]
): {
  relatedProducts: string[];
  variantRelatedProducts: Record<string, Array<string>>;
} => {
  const variantRelatedProducts = variants.reduce(
    (acc: Record<string, string[]>, next) => {
      const swatch = next.attributes?.find(a => a.name === AttributeName.Swatch)
        ?.value.key;

      const variantRelatedProducts = next.attributes?.find(
        a => a.name === AttributeName.VariantRelatedProducts && a.value.length
      );

      if (variantRelatedProducts) {
        if (acc[swatch]) {
          reduceRelatedProducts(variantRelatedProducts.value).forEach(i => {
            if (!acc[swatch].includes(i)) {
              acc[swatch].push(i);
            }
          });
        } else {
          acc[swatch] = reduceRelatedProducts(variantRelatedProducts.value);
        }
      }
      return acc;
    },
    {}
  );

  const relatedProducts = getCustomAttribute(
    masterVariant.attributes,
    AttributeName.RelatedProducts
  );

  return {
    relatedProducts: reduceRelatedProducts(relatedProducts),
    variantRelatedProducts,
  };
};

type ToProductDetails = (args: {
  product: ProductProjection;
  locale: ApiLocale;
  origin: string;
}) => ProductDetailsModel | null;

export const toProductDetails: ToProductDetails = ({
  product,
  locale,
  origin,
}) => {
  try {
    const t = translate(locale.language);
    const masterVariantSku = product.masterVariant.sku as string;

    const variants = toVariantMap({
      variants: [product.masterVariant, ...product.variants],
      slug: product.slug?.en,
      locale: locale.locale,
    });

    if (
      Object.keys(variants).length === 0 ||
      !product.masterVariant ||
      !variants[masterVariantSku]
    ) {
      return null;
    }

    const {
      name: productTypeName,
      swatches,
      sizes,
      availableSwatches,
      availableSizes,
      skuLookup,
    } = toProductType({
      productType: product.productType.obj as ProductType,
      variants,
    });

    const alternates = toAlternates(
      [product.masterVariant, ...product.variants],
      product?.slug,
      locale
    );

    const sizeGuide = getEnumAttribute(
      product.masterVariant?.attributes,
      AttributeName.SizeGuide
    );

    const activity = getLocalisedEnumAttribute(
      product.masterVariant?.attributes,
      AttributeName.Activity,
      'en'
    );

    const techSpecs = getLocalisedEnumSetAttribute(
      product.masterVariant?.attributes,
      AttributeName.techSpec,
      locale.language
    );

    const primaryCategory = getPrimaryCategoryAttribute(
      product.masterVariant?.attributes,
      AttributeName.PrimaryCategory,
      locale.language
    );

    const colorWays = toColorWays(
      [product.masterVariant, ...product.variants],
      locale,
      product?.slug.en
    );

    const model: ProductDetailsModel = {
      id: product.id,
      parentKey: product.key,
      slug: product.slug?.en,
      name: t(product.name),
      metaTitle: t(product.metaTitle),
      description: t(product.description),
      metaDescription: t(product.metaDescription),
      sizeGuide: [sizeGuide?.key].join(''),
      activity: [activity?.label].join(''),
      masterVariant: masterVariantSku,
      variants: variants as NonEmptyVariants,
      productType: productTypeName,
      primaryCategory,
      relatedProductsSkus: getRelatedProductSkus(product.masterVariant, [
        product.masterVariant,
        ...product.variants,
      ]),
      swatches,
      sizes,
      availableSwatches,
      availableSizes,
      skuLookup,
      origin,
      colorWays,
      techSpecs,
      alternates,
      defaultColorWay: selectDefaultColorWay(colorWays, masterVariantSku),
    };

    return model;
  } catch (e: any) {
    if (e.message) {
      sendWarningReport(e.message);
    } else {
      sendReport(e);
    }
    return null;
  }
};
